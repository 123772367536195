import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import AuthPlugin from "./plugin";
import getAuthentication from "./strategies/getHTTPStrategy";
import App from './App.vue';
import moment from './plugins/moment.js';

// router setup
import router from './routes/starterRouter';
import i18n from './i18n';
import './registerServiceWorker'
import store from './store'


let configurl = {
  'connectedURL': 'https://cfpadmin.urevent.fr/Scanapi/connected',
  'loginURL': 'https://cfpadmin.urevent.fr/Scanapi/login',
  'logouURL': 'https://cfpadmin.urevent.fr/Scanapi/logout',
};

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(moment);
Vue.use(AuthPlugin, {
  strategy: getAuthentication(configurl)
});

Vue.filter('formatDate', function(value) {
  if (value) {
     return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  i18n
});
