import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sessions: []
  },
  getters: {
    sessionNow: state => {
      let live_session = state.sessions.filter(item => item.live_session === 'live')
      return live_session
    },
    allRooms: state => {
      function groupBy(objectArray, property) {
        const allroom = [];
        return objectArray.reduce((acc, obj) => {
          const key = obj[property];
          if (!acc[key]) {
            acc[key] = [];

            obj['type'] = 'danger';
            if (obj['live_session'] != 'live') {
              obj['title_session'] = "Pas session"
            }
            allroom.push(obj);
          }
          // Add object to list for given key's value
          //  if(obj['live_session'] == 'live'){
          //  }           
          return allroom;
        }, {});
      }
      //  state.sessions = state.sessions.sort((a,b) => a.live_session-b.live_session)

      const allrooms = groupBy(state.sessions, 'roomname_session');
      return allrooms
    }
  },
  mutations: {
    LOAD_SESSIONSLIST(state, payload) {
      state.sessions = payload
    }
  },
  actions: {
    loadSessionslist(context) {
      fetch("https://cfpadmin.urevent.fr/checkapi/sessions")
        .then(
          (response) => response.json()
        )
        .then((json) => {
          if (json === 405) {
            console.log('pas récupéré la liste des sessions')
          } else {

            for (let i in json) {
              var t = json[i]['start_session'].split(/[- :]/);
              json[i]['start_session'] = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3] - 1, t[4], t[5]));
              var e = json[i]['end_session'].split(/[- :]/);
              json[i]['end_session'] = new Date(Date.UTC(e[0], e[1] - 1, e[2], e[3] - 1, e[4], e[5]));
              var today = new Date();
              if (json[i]['start_session'] <= today && json[i]['end_session'] >= today) {
                json[i]['live_session'] = 'live';
              } else {
                json[i]['live_session'] = 'offline'
              }
            }
            context.commit('LOAD_SESSIONSLIST', json);
          }
        });

    }
  },
  modules: {
  }
})
