<template>
  <div class="row">
    <div class="col-12">
      <card type="chart">
        <template slot="header">
          <h5 class="card-category">Sessions en cours</h5>
          <h3 class="card-title">
            <i class="tim-icons icon-sound-wave text-info "></i>
          </h3>
        </template>
        <div class="chart-area">
          <bar-chart
            style="height: 100%"
            ref="bigChart"
            :chart-data="blueBarChart.chartData"
            :gradient-stops="blueBarChart.gradientStops"
            :extra-options="blueBarChart.extraOptions"
          >
          </bar-chart>
        </div>
      </card>
    </div>
    <!-- Room Cards -->
    <!-- <div class="col-lg-3 col-md-6" v-for="(room, index) in $store.getters.allRooms" :key="index">
      <stats-card
        :title="room.roomname_session"
        :type="room.type"
        :start="room.start_session"
        :end="room.end_session"
        :texte="room.title_session"
      >
        <div slot="footer" v-html="room.title_session"></div>
      </stats-card>
    </div> -->
  </div>
</template>
<script>
import BarChart from '@/components/Charts/BarChart';
import StatsCard from 'src/components/Cards/StatsCard';
import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';

export default {
  components: {
    BarChart,
    // StatsCard
  },
  name: 'dash',
  data() {
    return {   
      today: this.$moment(String("1995-12-25")).format('MM'),
      blueBarChart: {
        extraOptions: chartConfigs.barChartOptions,
        chartData: {
          labels: [],
          datasets: [
            {
              label: 'Occupation',
              fill: true,
              borderColor: config.colors.info,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: []
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      }
    };
  },
  computed: {
  },
  methods: {
    initBigChart(index) {
      let bigChartLabels = [];
      let bigChartData = [];
      for (let i in this.$store.getters.sessionNow) {
        bigChartLabels.push(this.$store.getters.sessionNow[i]['roomname_session'])
        bigChartData.push(this.$store.getters.sessionNow[i]['nb_registered_scan'])
      }
      let chartData = {        
        labels: bigChartLabels,
        datasets: [{
          label: 'Occupation',
          fill: true,
          borderColor: config.colors.info,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: bigChartData
        }],
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.blueBarChart.chartData = chartData;
    }
  },
  mounted() {
    this.$store.dispatch('loadSessionslist');
    this.initBigChart(0);
  }
};
</script>
<style></style>
