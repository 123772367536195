<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <div class="col-5">
        <button type="button" class="btn btn-round btn-icon btn-fab" :class="`btn-${type}`">
          <i class="tim-icons icon-zoom-split"></i>
          </button>
      </div>
      <div class="col-7" v-if="$slots.content || title || subTitle">
        <div class="numbers">
          <slot>
            <p v-if="subTitle" class="card-category">{{ subTitle }}</p>
            <h3 v-if="title" class="card-title">{{ title }}</h3>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer || texte || end || start">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from './Card.vue';

export default {
  name: 'stats-card',
  components: {
    Card
  },
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    title: String,
    subTitle: String,
    end: Date,
    start: Date,
    texte: String
  }
};
</script>
<style>
.card-stats .info-icon {
  height: 40px;
  width: 40px;
}
</style>
